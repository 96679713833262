<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="py-4 breadcrumb-bg"
      style="
        background-image: url(../assets/img/background/page-title-bg-img6.jpg);
      "
    >
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">活動實錄</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                活動實錄
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <div class="d-flex justify-content-center">
          <div class="button-group">
            <button v-for="(val, key, index) in option.getFilterData" class="button" :class="[key===filterOption? 'is-checked' : '']" @click="filter(key)" :key="index + 1">{{key}}
            </button>
          </div>
        </div>

        <div id="gallery-grid">
          <div>
            <isotope class="row grid" ref="cpt" id="root_isotope1" :item-selector="'element-item'" :list="list" :options='option' @filter="handleFilter" @sort="sortOption=arguments[0]" @layout="currentLayout=arguments[0]">
              <div class="col-md-4 col-lg-3 col-xs-12 element-item" :class="item.filterText" v-for="(item, index) in images" :key="index + 1">
                <div class="media media-hoverable justify-content-center">
                  <div class="position-relative w-100">
                    <img
                      class="media-img w-100 adjust-height"
                      data-src="@/assets/img/gallery/gallery-img1.jpg"
                      :src="item.banner"
                      alt="gallery-img"
                    />
                    <router-link :to="`/record/${ item.id }`" class="media-img-overlay">
                      <div class="btn btn-squre">
                        <i class="fa fa-eye"></i>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </isotope>
            <div class="w-100 text-center">
              <h2 class="text-danger">{{ filterMsg }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import isotope from 'vueisotope'

export default {
  components: {
    isotope
  },
  data () {
    return {
      filterMsg: '',
      images: [],
      filterImages: [],
      filterText: '',
      list: [],
      filterOption: 'show all',
      option: {
        itemSelector: '.element-item',
        getFilterData: {
          'VIEW ALL': function () {
            return true
          },
          營隊: function (el) {
            return el.category === '營隊'
          },
          課程: function (el) {
            return el.category === '課程'
          },
          企業講座: function (el) {
            return el.category === '企業講座'
          },
          公益團體: function (el) {
            return el.category === '公益團體'
          }
        }
      },
      dataMap: {
        營隊: 'camp',
        課程: 'course',
        企業講座: 'corporate',
        公益團體: 'charity'
      }
    }
  },
  created () {
  },
  mounted () {
    require('@/assets/js/base.js')
    const vm = this
    vm.getData()
  },
  methods: {
    filter (key) {
      const vm = this
      vm.$refs.cpt.filter(key)
    },
    getData () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/event/list`

      vm.axios.post(api, {
        token: tokenGenerator(),
        categoryId: ['1', '2', '3', '4'],
        page: 1,
        pageSize: 10
      })
        .then((res) => {
          vm.list = res.data.content
          vm.images = res.data.content
          vm.images.forEach((image) => {
            image.filterText = vm.dataMap[image.category]
          })
        })
    },
    handleFilter (args) {
      this.filterMsg = ''
      this.filterOption = arguments[0]
      setTimeout(() => {
        const rootIsotope1 = document.querySelector('#root_isotope1')
        for (let i = 0; i < rootIsotope1.children.length; i++) {
          const child = rootIsotope1.children[i]
          if (child.style.display === '') {
            this.filterMsg = ''
            return null
          }
        }
        this.filterMsg = '目前尚無相關活動'
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-height {
  height: 213px;
  object-fit: cover;
}
</style>
